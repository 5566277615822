@use "../abstracts" as *;

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    color: color(blackScale);
    font-weight: 400;

    :focus {
        outline: none;
    }
}

body {
    background-color: white;
}

a {
    color: inherit;
    font-size: inherit;
    @extend %transitionDefault;

    &:hover {
        color: color(primary);
        text-decoration: unset;
    }
}

i,
strong,
h2 strong {
    font-size: inherit;
    color: inherit;
    font-size: inherit;
}

p {
    img {
        max-width: 100% !important;
        height: auto !important;
        object-fit: cover;
    }
}

ol,
ul {
    list-style: none;
    padding-left: unset;
    margin-bottom: unset;
}

button {
    border: unset;
    @extend %transitionDefault;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 1rem;
}

h1 {
    font-size: size(title, 500);
    color: color(primary);
    font-weight: bold;
}

h2 {
    font-size: size(title, 400);
}

h3,
h4 {
    font-size: size(title);
}

h5 {
    font-size: size(title, 200);
}

h6 {
    font-size: size(title, 100);
}
