@use "../abstracts" as *;

%textStyle {
    color: white !important;
    width: 100%;
    height: 100%;
    font-family: Krub;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

%textStyleMobile {
    color: white !important;
    width: 100%;
    height: 100%;
    font-family: Krub;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    font-size: size(text, 400);
}

.nav {
    width: 100%;
    @extend %transitionDefault;

    &__list {
        width: 100%;


        &--drop {
            top: 100%;
            left: 0;
            z-index: 100;
            flex-direction: column !important;
            align-items: flex-start !important;
            justify-content: flex-start !important;
            padding: 0.5rem;
            @extend %transitionDefault;
        }
    }

    &__item {
        position: relative;
        color: color(primary);
        padding: 0 0.5rem;
        cursor: default;

        &-logo{
            display: none;
        }

        @include for-tablet-portrait-down{
            margin-bottom: 30px;
        }

        &-redesocial{
            color: white;
            width: 20px;
            height: 20px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover{

                scale: 1.2;
            }
        }

        &:not(:first-of-type, :last-of-type, :nth-child(2))::before{
            content: "";
            position: absolute;
            left: -50%;
            top: 25%;
            width: 1px;
            height: 45%;
            background-color: #FFFFFF33;

        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 2px;
            background-color: white;
            @extend %transitionDefault;
        }

        &:hover {
            color: color(primary);
            &::after {
                width: 100%;
            }
        }

        &:not(:last-of-type) {
            margin-right: 1rem;
        }

        &--drop {
            position: relative;

            span {
                @extend %textStyle;
                @include for-phone-only {
                  justify-content: flex-start !important;
                }
            }

            .nav__item-logo{
                display: none !important;
            }

            .nav__item-redesocial{
                display: none;
            }

            &-item {
                position: relative;
                width: 100%;

                a {
                    padding: 0.5rem 0;
                }

                &::after {
                    background-color: color(primary);
                }

            }

            &:hover {
                background-color: white;

                span {
                    color: color(primary) !important;
                }

                & > ul {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        @include for-tablet-portrait-down{


            &::after {
                content: "";
                position: absolute;
                bottom: -5px;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: white;
            }

            &:hover {
                a{
                    color: #c4c4c4 !important;
                }
                &::after {
                    background-color: #c4c4c4 !important;
                }
            }
        }
    }

    &__link {
        @extend %textStyle;
        @include flex(flex, column, center, center);
        @include for-phone-only{
          @include flex(flex, column, flex-start, center);
        }

        @include for-tablet-portrait-down{
            align-items: start;
        }
    }

    @include for-phone-only{
      &__list {
        &--drop {
          a {
            color: color(blackScale, 300) !important;
          }
        }
      }

      &__item{
        &:not(:first-of-type)::before{
            width: 0px;
            height: 0px;
            display: none;
        }
      }
    }

    @include for-desktop-up {
        &__list {
            @include flex(flex, row, center, space-between);

            &--drop {
                position: absolute;
                min-width: 200px;
                opacity: 0;
                visibility: hidden;
                background-color: white;

                a {
                    color: color(blackScale, 300) !important;
                }
            }
        }

        &__item {

            &--drop {
                &-item {
                    a {
                        align-items: flex-start;
                    }

                    &:not(:last-of-type) {
                        border-bottom: 1px solid #e8e8e8;
                        margin-left: unset;
                    }
                }
            }
        }

        &__item,
        &__link {
            height: 100%;
        }
    }
}

.nav-mobile {
    position: fixed;
    top: 0;
    right: -240px;
    z-index: 100;
    width: 240px;
    height: 100%;
    overflow-y: scroll;
    background-color: color(primary, 600);
    transition: all 0.5s ease-in-out;

    & > ul {
        overflow-y: scroll;
        height: 100%;
        padding-top: 2rem;
        padding-left: 2rem;
    }

    &__overlay {
        content: "";
        position: fixed;
        top: 0;
        right: -100%;
        z-index: 99;
        width: 100%;
        height: 100%;
        background-color: color(grayScale, 600, 0.35);
        @extend %transitionDefault;
    }

    &__close {
        text-align: right;
        font-size: size(text, 400);
        margin: 2rem 1rem 0 0;
        color: white;

        @include for-tablet-portrait-down{
            margin-top: 50px;
        }

    }

    &__button {
        width: 100%;
        color: white;
        font-size: size(text, 400);
    }

    @include for-desktop-up {
        position: static;
        width: 100%;
        height: 100%;
        overflow-y: unset;
        background-color: transparent;

        & > ul {
            overflow-y: unset;
            height: 100%;
            padding-top: unset;
            padding-left: unset;
        }

        &__button,
        &__close {
            display: none;
        }
    }

    &__active,
    &__active > &__overlay {
        right: 0;
    }
}
