@use "../abstracts" as *;

.title {
    margin-bottom: unset;

    &::after {
        content: "";
        display: block;
        width: 66px;
        height: 3px;
        margin: 0.2em auto;
        background-color: color(primary);
    }
}

.placeholder {
    background-color: color(placeholder);

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.map {
    .embed-responsive {
        max-height: 283px;
    }
}
