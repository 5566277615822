@use "../abstracts" as *;

.information {
    background: #091242;
    padding: 10px !important;

    &___logo {

        figure {
            width: 152px;
            height: auto;
        }
    }

    &__title {
        color: white;
        font-weight: bold;
        font-size: size(text, 400);
        margin-bottom: 0.5rem;
    }

    &-info {
        background-color: #091242;

        &__item {
            position: relative;
            @include grid(grid, auto max-content, 100%, 0.5rem);
            align-items: center;
            padding: 0.5rem 1rem;


            @include for-tablet-portrait-down {

                &:nth-child(2),
                &:nth-child(3) {
                    flex: 0 0 50%;
                    width: 50%;
                }
            }

            @media (max-width: 450px) {
                &-icon {
                    width: 48px !important;
                    height: 48px !important;
                    padding: 12px !important;

                    i {
                        width: 48px !important;
                        height: 48px !important;
                        font-size: 14px !important;
                        text-align: center !important;
                        padding-top: 6px !important;
                    }

                    img {
                        height: 16px !important;
                    }
                }

                p,
                a,
                strong {
                    font-size: 12px !important;
                }
            }

            &-icon {
                color: #FFFFFF;
                margin: auto;
                padding: 16px;
                width: 64px;
                height: 64px;
                background: #111C55;
                border: 1px solid #273270;
                border-radius: 90px;
                align-items: center;
                justify-content: center;
                margin-right: 12px;
                margin-left: 0px;

                i {
                    width: 64px;
                    height: 64px;
                    font-size: 20px;
                    text-align: center;
                    padding-top: 6px;
                }

                img {
                    height: 17px;
                }
            }

            &:first-of-type {
                padding-left: 0;
            }

            p,
            a,
            strong {
                color: #FFFFFF !important;
                font-family: Krub;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;
                overflow-wrap: break-word;
                width: 100%;

            }

            div {
                display: flex;
                flex-direction: column;
            }

        }
    }

    &__link {
        color: white;
        font-size: size(text);

        &:hover {
            color: color(primary, 100);
        }
    }

    &__icon {
        color: white;
        font-size: size(text);
        margin-right: 0.5rem;
    }

    &__column {
        margin-bottom: 1rem;

        @include for-desktop-up {
            margin-bottom: unset;

            &:not(:last-of-type) {
                border-right: 2px solid white;
            }

            &:nth-child(2) {
                padding-left: 5rem;
            }
        }
    }

    @media (max-width: 1200px) {
        background-image: unset !important;
    }
}

.rights {
    display: flex;
    align-items: center;
    min-height: 40px;
    padding: 0.5rem 0px 0.5px;
    border-top: 1px solid #4E5683;
    background: #091242;

    .rodape {
        .rights__text {
            display: flex;
            justify-content: space-between;
            color: white;
            text-align: center;

            a {
                color: inherit;
                text-decoration: none;

                &:hover {
                    color: color(primary);
                }
            }

            p {
                font-size: 14px;
                color: white;
                margin-bottom: 0;
            }

            @include for-phone-only {
                flex-direction: column;
            }
        }
    }
}
