@use "../abstracts" as *;

.header {
    /* .top {
        background-color: color(primary, 700);
        min-height: 48px;
        padding: unset !important;

        &-contact {
            color: white;
            font-size: size(text, 200);

            i {
                font-size: size(text, 300);
            }

            span {
                color: white;
            }

            &__link {
                &:hover {
                    color: color(primary, 500);
                }
            }
        }
    } */

    .col-lg-7{

        @include for-phone-only{
            flex: 0 0 100%;

        }
    }

    &__logo{
      // img {
      //   width: 152px;
      //   height: 124px;
      // }

        @include for-tablet-portrait-down{
            text-align: center;
        }
        a{
            width: 100%;
            text-align: center;
            @include for-desktop-up{
                 margin: fit-content;
            }
        }

    }
    &-floating{
        visibility: visible;
        opacity: 1;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 3;
        width: 100%;
        // height: 200px;
        transition: all 0.5s ease-in-out;

        &__hidden{
            visibility: hidden;
            opacity: 1;
            top: -50%;
        }

        .nav__item-logo{

            display: flex;
            height: 100px;

            img{
                height: 100px !important;
            }
        }

        .header__nav{
            background: #091242!important;
            height: auto !important;
            padding: 10px 0px 10px 0px;
        }
    }
    &-info {
        background-color: #091242;

        &__item {
            position: relative;
            @include grid(grid, auto max-content, 100%, 0.5rem);
            align-items: center;
            display: none;
            padding: 0.5rem 1rem;
            @include for-desktop-up{
                display: flex;
            }


            &-icon{
                color: #FFFFFF;
                margin: auto;
                padding: 15px;
                width: 63px;
                height: 63px;
                background: #111C55;
                border: 1px solid #273270;
                border-radius: 90px;
                align-items: center;
                justify-content: center;

                img{
                    height: 17px;
                }
            }

            &:first-of-type {
                padding-left: 0;
            }

            p, a, strong{
                color: #FFFFFF;
                font-family: Krub;
                font-size: 14px;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;

            }

            div{
                display: flex;
                flex-direction: column;
            }

        }

        &__link {
            font-size: size(text, 200);
        }
    }

    &__nav {
        height: 68px;
        background: #091242;
        width: 100%;

        @include for-desktop-up{
            background-color: #273270;
        }
    }
}
